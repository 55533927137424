<template>
  <div>
    <div class="md-layout-item ">
      <span class="md-display-1">Ingreso</span>
      <hr>
      <form @submit.prevent="login">
        <md-field :class="{'md-invalid': errors.email }">
          <label>Correo electrónico</label>
          <md-input v-model="user.email" type="email"></md-input>
          <span class="md-error" v-if="errors.email">{{errors.email}}</span>
        </md-field>
        <md-field :class="{'md-invalid': errors.password }">
          <label>Clave</label>
          <md-input v-model="user.password" type="password"></md-input>
          <span class="md-error" v-if="errors.password">{{errors.password}}</span>
        </md-field>
          <button disabled
            v-if="loadding > 100 && loadding > 0"
            id="payment-button"
            class="btn btn-lg btn-info btn-block">
            Enviando: {{loadding}} %
          </button>
          <md-button type="submit" class="md-primary">Ingresar</md-button>
      </form>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  created() {
    this.$store.commit('SET_LAYOUT', 'login');
  },
  name: 'Login',
  data() {
    return {
      sending: false,
      errors: [],
      user: [],
      result: [],
      base: process.env.VUE_APP_BASE_URL,
      url: process.env.VUE_APP_BASE_URL + '/api-token-auth/',
      url_user: 'user/',
    };
  },
  methods: {
    login() {
      this.errors = [];
      if (!this.user.email) {
        this.errors.email = 'El correo es obligatorio.';
        return false;
      }
      if (!this.user.password) {
        this.errors.password = 'La clave es obligaroira.';
        return false;
      }
      const payload = {};
      payload.email = this.user.email;
      payload.password = this.user.password;
      const self = this;
      let usercustom = '';
      this.sendRequest(payload).then((data) => {
        usercustom = self.parseJwt(data.data.token);
        usercustom.token = data.data.token;
        self.save_storage('user', usercustom);
        self.$store.dispatch('login', { user: usercustom });
        self.save_storage('_token', true);
        self.get_Company();
        self.$swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Acceso exitoso',
          showConfirmButton: false,
          timer: 1500,
        });

      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible autenticar.', 'error');
      });
      return null;
    },
    unixTimetoDate(time) {
      return new Date(time * 1000).toISOString().slice(0, 19).replace('T', ' ');
    },
    parseJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const dataJWT = JSON.parse(window.atob(base64));
        return dataJWT;
      } catch (err) {
        return null;
      }
    },
    redirect(_user) {
      if (!localStorage.getItem('company')){
        setTimeout(() => {
          this.$router.push('/')
        }, 300);
        return
      }
      if (!localStorage.getItem('store')){
        let company = localStorage.getItem('company')
        setTimeout(() => {
          this.$router.push(`/stores/${company}`)
        }, 300);
      }
    },
    get_Company() {
      const self = this
      this.getCompanyRequest(this.url_user).then(function (data) {
        self.result = data.data;
        if (self.result.length > 0){
          self.save_storageCompany('office', self.result[0].company);
          self.save_storageCompany('office_name', self.result[0].company_name);
          if (self.result[0].logo_url){
            self.save_storageCompany('office_logo', self.result[0].logo_url);
          }
          self.save_storageCompany('id_fiscal_year', self.result[0].id)
          self.redirect()
        }else{
          self.save_storageCompany('office', self.result.company);
          self.save_storageCompany('office_name', self.result.company_name);
          if (self.result.logo_url){
            self.save_storageCompany('office_logo', self.result.logo_url);
          }
          setTimeout(() => {
            self.$router.push('/fiscal_year')
          }, 300);
          return
        }
      }).catch(function (error) {
        if (typeof (error.data) === 'object') {
          const result = error.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.data, 'error')
      })
    },
    async sendRequest(payload) {
      const result = await this.$http.post(this.url, payload);
      return result;
    },
    async getRequest(payload, url) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
    async getCompanyRequest(url) {
      const result = await this.$http.get(url);
      return result;
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    save_storageCompany(key, data) {
      localStorage.setItem([key], data);
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage() {
      if (JSON.parse(localStorage.getItem('user'))) {
        return localStorage.getItem('user');
      }
      return null;
    },
  },
  computed: {
    loadding() {
      return this.$store.state.progress;
    },
  },
};

</script>
<style lang="css" scoped>
  input {
    color: black !important;
    -webkit-text-fill-color: black !important;
  }

</style>
